import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import ProtectedRoute from "./utils/ProtectedRoute";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Deliveries = lazy(() => import("./deliveries/Deliveries"));
const Sales = lazy(() => import("./sales/Sales"));
const Sessions = lazy(() => import("./session/Sales"));
const WareHouse = lazy(() => import("./ware-house"));
const WareHouseManager = lazy(() => import("./warehouse-manager"));
const POS = lazy(() => import("./pos/Pos"));
const Permissions = lazy(() => import("./session/Pages/Permissions"));
const FollowUps = lazy(() => import("./form-elements/FollowUps"));
const PurchasePatterns = lazy(() =>
  import("./form-elements/FollowUps/PurchasePatterns")
);
const Recommendations = lazy(() =>
  import("./form-elements/FollowUps/Recommendations")
);
const SalesUsers = lazy(() => import("./sales/SalesUsers"));
const SalesVisits = lazy(() => import("./sales/SalesUsers/SalesVisits"));
const SalesVisitDetail = lazy(() =>
  import("./sales/SalesUsers/SalesVisitDetail")
);

const Agent = lazy(() => import("./dashboard/AgentDashboard"));

const ViewEmailsHistory = lazy(() =>
  import("./form-elements/EmailsHistory/ViewEmailsHistory")
);

const ViewEmailsCcBcc = lazy(() =>
  import("./form-elements/EmailsCcBcc/ViewEmailsCcBcc")
);

const ViewTemplates = lazy(() =>
  import("./form-elements/Templates/ViewTemplates")
);

const ViewBanners = lazy(() => import("./form-elements/Banners/ViewBanners"));

const BasicTable = lazy(() => import("./tables/BasicTable"));

const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));

const Terms = lazy(() => import("./Terms/Terms"));

const Login = lazy(() => import("./user-pages/Login"));
const ResetPassword = lazy(() => import("./user-pages/ResetPassword"));
const Register1 = lazy(() => import("./user-pages/Register"));

const SuccessMessage = lazy(() => import("./messages/SuccessMessage"));
const ErrorMessage = lazy(() => import("./messages/ErrorMessage"));

const AddProduct = lazy(() => import("./form-elements/Product/AddProduct"));
const ViewProduct = lazy(() => import("./form-elements/Product/ViewProduct"));
const EditProduct = lazy(() => import("./form-elements/Product/EditProduct"));

const AddProducers = lazy(() =>
  import("./form-elements/Producers/AddProducers")
);
const ViewProducers = lazy(() =>
  import("./form-elements/Producers/ViewProducers")
);
const EditProducers = lazy(() =>
  import("./form-elements/Producers/EditProducers")
);

const AddAgents = lazy(() => import("./form-elements/Agents/AddAgents"));
const ViewAgents = lazy(() => import("./form-elements/Agents/ViewAgents"));
const EditAgents = lazy(() => import("./form-elements/Agents/EditAgents"));
const ViewUsersOfAgent = lazy(() =>
  import("./form-elements/Agents/ViewUsersOfAgent")
);

const ProductInRegion = lazy(() =>
  import("./form-elements/ProductInRegion/AddProductInRegion")
);
const EditProductInRegion = lazy(() =>
  import("./form-elements/ProductInRegion/EditProductInRegion")
);
const ViewProductInRegion = lazy(() =>
  import("./form-elements/ProductInRegion/ViewProductInRegion")
);

const Offer = lazy(() => import("./form-elements/Offer/Offer"));
const EditOffer = lazy(() => import("./form-elements/Offer/EditOffer"));
const ViewOffer = lazy(() => import("./form-elements/Offer/ViewOffer"));

const AddNewDeliveryDay = lazy(() =>
  import("./form-elements/AddNewDelivery/AddNewDeliveryDay")
);
const ViewDeliveryGroups = lazy(() =>
  import("./form-elements/DeliveryGroups/ViewDeliveryGroup")
);
const ViewRoutes = lazy(() =>
  import("./form-elements/AddNewDelivery/ViewRoutes")
);
const EditDeliveryDay = lazy(() =>
  import("./form-elements/AddNewDelivery/EditDeliveryDay")
);

const AddOrderValue = lazy(() =>
  import("./form-elements/OrderValue/AddOrderValue")
);
const EditOrderValue = lazy(() =>
  import("./form-elements/OrderValue/EditOrdervalue")
);
const ViewOrderValue = lazy(() =>
  import("./form-elements/OrderValue/ViewOrdervalue")
);

const AddPayment = lazy(() => import("./form-elements/Payment/AddPayment"));
const EditPayment = lazy(() => import("./form-elements/Payment/EditPayment"));
const ViewPayment = lazy(() => import("./form-elements/Payment/ViewPayment"));

const AddTaxRate = lazy(() => import("./form-elements/TaxRate/AddTaxRate"));
const ViewTaxRate = lazy(() => import("./form-elements/TaxRate/ViewTaxRate"));
const EditTaxRate = lazy(() => import("./form-elements/TaxRate/EditTaxRate"));

const AddNewRegion = lazy(() => import("./form-elements/Region/AddNewRegion"));
const ViewRegion = lazy(() => import("./form-elements/Region/ViewRegion"));
const EditRegion = lazy(() => import("./form-elements/Region/EditRegion"));

const AddCategory = lazy(() => import("./form-elements/Category/Addcategory"));
const ViewCategory = lazy(() =>
  import("./form-elements/Category/ViewCategory")
);
const EditCategory = lazy(() =>
  import("./form-elements/Category/EditCategory")
);

const Report = lazy(() => import("./form-elements/Report/Reports"));
const SalesReport = lazy(() => import("./form-elements/Report/SalesReports"));

const Order = lazy(() => import("./form-elements/Order/Order"));
const OrderDetail = lazy(() => import("./form-elements/Order/OrderDetail"));

const Customers = lazy(() => import("./form-elements/Customers/Customers"));
const CustomersSales = lazy(() =>
  import("./form-elements/Customers/CustomersSales")
);
const EditCustomers = lazy(() =>
  import("./form-elements/Customers/EditCustomer")
);
const Contacts = lazy(() => import("./form-elements/Customers/Contacts"));
const Whatsapp = lazy(() => import("./form-elements/Customers/Whatsapp"));
const Feedback = lazy(() => import("./form-elements/Customers/Feedbacks"));

const AddNotifications = lazy(() =>
  import("./form-elements/Notifications/AddNotifications")
);
const SendWhatsapp = lazy(() =>
  import("./form-elements/Notifications/AddWhatsapp")
);
const ViewNotifications = lazy(() =>
  import("./form-elements/Notifications/ViewNotifications")
);

const ViewNotificationTemplates = lazy(() =>
  import("./form-elements/Notifications/ViewNotificationTemplates")
);

const AddNotificationTemplates = lazy(() =>
  import("./form-elements/Notifications/AddNotificationTemplates")
);

const ViewNotificationTemplatesDetails = lazy(() =>
  import("./form-elements/Notifications/ViewNotificationTemplateDetails")
);

const ViewNotificationsDetails = lazy(() =>
  import("./form-elements/Notifications/ViewNotificationDetails")
);

const AddNewLocation = lazy(() =>
  import("./form-elements/Location/AddNewLocation")
);
const ViewLocation = lazy(() =>
  import("./form-elements/Location/ViewLocation")
);
const ViewLocationById = lazy(() =>
  import("./form-elements/Location/ViewLocationById")
);
const EditLocation = lazy(() =>
  import("./form-elements/Location/EditLocation")
);

const ViewPickingList = lazy(() =>
  import("./ware-house/picking-list/PickingList")
);

const FidelityProgram = lazy(() => import("./form-elements/FidelityProgram"));
const ViewFidelityUsersById = lazy(() =>
  import("./form-elements/FidelityProgram/Fidelity-users/ViewFidelityUsersById")
);

const ViewPODetail = lazy(() => import("./ware-house/po/ViewPODetail"));

const ViewWareHouseProductdetail = lazy(() =>
  import("./ware-house/Invoice/ViewWareHouseProductdetail")
);

const Map = lazy(() => import("./Map"));

const ViewReturnRequests = lazy(() =>
  import("./deliveries/returnRequests/ViewReturnRequests")
);

const ReturnRequestDetail = lazy(() =>
  import("./deliveries/returnRequests/ReturnRequestDetail")
);

const CustomerInterviews = lazy(() =>
  import("./form-elements/customer-interviews/CustomerInterviews")
);
const CreateInterview = lazy(() =>
  import("./form-elements/customer-interviews/CreateInterview")
);
const EditInterview = lazy(() =>
  import("./form-elements/customer-interviews/EditInterview")
);

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/dashboard"
            component={Dashboard}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/sales"
            component={Sales}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/permissions/:id"
            component={Permissions}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-sessions"
            component={Sessions}
          />

          <ProtectedRoute
            accesstype={["admin", "producer", "warehouse_manager"]}
            exact
            path="/view-warehouse"
            component={WareHouse}
          />

          <ProtectedRoute
            accesstype={["admin", "producer", "warehouse_manager"]}
            exact
            path="/warehouse-manager"
            component={WareHouseManager}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-fidelity-program"
            component={FidelityProgram}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-fidelity-user/:id"
            component={ViewFidelityUsersById}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-picking-list"
            component={ViewPickingList}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-POdetail/:id"
            component={ViewPODetail}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-warehouse-detail/:id"
            component={ViewWareHouseProductdetail}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-pos"
            component={POS}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/sales/user/:id"
            component={SalesUsers}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/sales/visit/:agentId/:id"
            component={SalesVisits}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/sales/visit/:agentId/:userId/detail/:id"
            component={SalesVisitDetail}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/purchase-patterns"
            component={PurchasePatterns}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/follow-ups"
            component={FollowUps}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/recommendation"
            component={Recommendations}
          />

          <ProtectedRoute
            accesstype={["agent"]}
            exact
            path="/agent/dashboard"
            component={Agent}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-emails-history"
            component={ViewEmailsHistory}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-emails-cc-bcc"
            component={ViewEmailsCcBcc}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-templates"
            component={ViewTemplates}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            exact
            path="/view-banners"
            component={ViewBanners}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-new-region"
            component={AddNewRegion}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-region"
            component={ViewRegion}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-deliveries"
            component={Deliveries}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-region/:id"
            component={EditRegion}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-new-location"
            component={AddNewLocation}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-location-by-id/:id"
            component={ViewLocationById}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-location"
            component={ViewLocation}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-location/:id"
            component={EditLocation}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-location-map"
            component={Map}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/add-category"
            component={AddCategory}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/view-category"
            component={ViewCategory}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-category/:id"
            component={EditCategory}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-product"
            component={AddProduct}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-product"
            component={ViewProduct}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-product/:id"
            component={EditProduct}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/add-producers"
            component={AddProducers}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/view-producers"
            component={ViewProducers}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-producers/:id"
            component={EditProducers}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-agents"
            component={AddAgents}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-agents"
            component={ViewAgents}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-agents/:id"
            component={EditAgents}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/agents-users/:id"
            component={ViewUsersOfAgent}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-productIn-region"
            component={ProductInRegion}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-productIn-region/:id"
            component={EditProductInRegion}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-productIn-region"
            component={ViewProductInRegion}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-offer"
            component={Offer}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-offer/:id"
            component={EditOffer}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-offer"
            component={ViewOffer}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-report"
            component={Report}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-sales-report"
            component={SalesReport}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-order"
            component={Order}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-order-detail/:id"
            component={OrderDetail}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-customers"
            component={Customers}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/customer-sales"
            component={CustomersSales}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-customers/:id"
            component={EditCustomers}
          />
          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/contact-customers"
            component={Contacts}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/whatsapp-customers"
            component={Whatsapp}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/feedbacks"
            component={Feedback}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/add-notifications"
            component={AddNotifications}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/add-whatsapp"
            component={SendWhatsapp}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/view-notifications"
            component={ViewNotifications}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/view-notification-templates"
            component={ViewNotificationTemplates}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/create-notification-templates"
            component={AddNotificationTemplates}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/notification-templates/:id"
            component={ViewNotificationTemplatesDetails}
          />

          <ProtectedRoute
            accesstype={["admin", "agent"]}
            path="/notifications-details/:id"
            component={ViewNotificationsDetails}
          />

          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/add-delivery-day"
            component={AddNewDeliveryDay}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/view-delivery-day"
            component={ViewRoutes}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/delivery-groups"
            component={ViewDeliveryGroups}
          />
          <ProtectedRoute
            accesstype={["admin", "producer"]}
            path="/edit-delivery-day/:id/:day"
            component={EditDeliveryDay}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/add-order-value"
            component={AddOrderValue}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-order-value/:id"
            component={EditOrderValue}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/view-order-value"
            component={ViewOrderValue}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/add-payment"
            component={AddPayment}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-payment/:id"
            component={EditPayment}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/view-payment"
            component={ViewPayment}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/add-tax-rate"
            component={AddTaxRate}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/view-tax-rate"
            component={ViewTaxRate}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/edit-tax-rate/:id"
            component={EditTaxRate}
          />

          <ProtectedRoute path="/tables/basic-table" component={BasicTable} />

          <Route path="/user-pages/login-1" component={Login} />
          <Route path="/user-pages/register-1" component={Register1} />
          <Route path="/reset-password/:token" component={ResetPassword} />

          <Route path="/success-message" component={SuccessMessage} />
          <Route path="/error-message" component={ErrorMessage} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} />

          <Route path="/terms-and-conditions" component={Terms} />

          <ProtectedRoute
            accesstype={["admin"]}
            path="/return-requests"
            component={ViewReturnRequests}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/return-request-detail/:id"
            component={ReturnRequestDetail}
          />

          <ProtectedRoute
            accesstype={["admin"]}
            exact
            path="/customer-interviews"
            component={CustomerInterviews}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/customer-interviews/create"
            component={CreateInterview}
          />
          <ProtectedRoute
            accesstype={["admin"]}
            path="/customer-interviews/edit/:id"
            component={EditInterview}
          />

          <Redirect to="/user-pages/login-1" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
